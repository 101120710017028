export default {
  props: {
    forceCanvas: Boolean,
    verbose: Boolean
  },
  mounted() {
    if (!this.$parent._rendering) {
      //
      // When webpack refresh only the renderer (it's not supposed to happen
      // in prod)
      //
      console.log('RENDERER AUTO REFRESH')
      this.$emit('refresh')
    }
  },
  destroyed() {
    this._settings = null
    this._blocMap = null
  }
}
