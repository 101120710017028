import { render, staticRenderFns } from "./SelectionBound.vue?vue&type=template&id=32401722&scoped=true&"
import script from "./SelectionBound.vue?vue&type=script&lang=js&"
export * from "./SelectionBound.vue?vue&type=script&lang=js&"
import style0 from "./SelectionBound.vue?vue&type=style&index=0&id=32401722&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../jelly-app/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32401722",
  null
  
)

export default component.exports