export default class EventMap {
  constructor() {
    this.map = new Map()
  }

  get size() {
    return this.map.size
  }

  set(index, bloc) {
    let events = this.map.get(index)
    if (!events) {
      events = []
      this.map.set(index, events)
    }
    if (events.indexOf(bloc) === -1) {
      events.push(bloc)
    }
  }

  has(index) {
    return this.map.has(index)
  }

  get(index) {
    return this.map.get(index)
  }

  fill(from, to, bloc) {
    for (from; from < to; from++) {
      this.set(from, bloc)
    }
  }
}
