<template>
  <div :class="rendererClass">
    <selection-bound
      :items="selectionRows"
      :style="getStyleSelection(layout)"
    />
    <div class="paper" :style="paperStyle">
      <slot name="header" />
      <div v-if="isEmpty" class="empty-slot">
        <slot name="empty" />
      </div>
      <part v-slot="{ part }" :parts="parts">
        <div
          class="row"
          v-for="(row, rowIndex) in part"
          :key="rowIndex"
          :style="getStyleGrid(row, layout)"
        >
          <!-- <div class="grid" > -->
          <div
            :class="measure.class"
            :ref="`measure-${measure.index}`"
            v-for="(measure, measureIndex) in row"
            :key="measureIndex"
            @click="selectFromMeasure(measure)"
          >
            <div class="bloc" v-if="measure.repeat">
              <span
                class="repeat"
                @click="selectFromRepeat(measure)"
                :ref="`chord-${getEventIndex(measure)}`"
              >
                <svg width="18px" height="18px">
                  <rect width="2" height="16" />
                  <rect width="4" height="4" rx="2" ry="2" />
                  <rect width="4" height="4" rx="2" ry="2" />
                </svg>
              </span>
            </div>
            <div class="blocs" v-else>
              <svg v-if="!measure.x" class="lines" width="100%" height="100%">
                <line x1="0" y1="100%" x2="100%" y2="0" />
                <line v-if="measure.xxxx" x1="0" y1="0" x2="100%" y2="100%" />
              </svg>
              <!-- BLOCS -->
              <div
                :class="bloc.class"
                v-for="(bloc, blocIndex) in measure.cells"
                :key="blocIndex"
              >
                <!-- CHORDS -->
                <chord
                  v-for="(chord, chordIndex) in bloc"
                  :ref="`chord-${chord.eventIndex}`"
                  :key="chordIndex"
                  :raw="chord.raw"
                  :class="chord.class"
                  :empty-char="chord.emptyChar"
                  :transpose="transpose"
                  @click.stop="selectFromChord(chord)"
                />
              </div>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </part>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import rendererMixin from '../../mixins/renderer-mixin'
import selectionMixin from '../../mixins/selection-mixin'

import chartBuilder from '../../processors/chart-builder'

import Part from '../misc/Part.vue'
import Chord from '../misc/Chord.vue'
import SelectionBound from '../misc/SelectionBound.vue'

export default {
  name: 'ChartRenderer',
  mixins: [rendererMixin, selectionMixin],
  components: { Part, Chord, SelectionBound },
  props: {
    locked: Boolean
  },
  data: () => ({
    parts: null,
    layout: null,
    transpose: null,
    inline: true,
    measureWidth: 0,
    isEmpty: false
  }),
  computed: {
    rendererClass() {
      const cls = ['chart-renderer']
      if (this.locked) cls.push('locked')
      return cls
    },
    paperStyle() {
      const layout = this.layout
      const styles = {}
      const colsMatch = layout?.match(/(\d)-cols/)
      if (!colsMatch && !this.isEmpty) {
        styles.display = 'block'
      } else {
        const parts = this.parts && this.parts()
        if (parts) {
          const max = parts.reduce((a, p) => {
            p = p.reduce((a, r) => Math.max(a, r.length), 0)
            return Math.max(a, p)
          }, 0)
          const paperPadding = this._settings.sheet.padding * 2
          styles['max-width'] = Number(max * 200 + paperPadding) + 'px'
        }
      }
      return styles
    }
    // isEmpty() {
    //   const parts = this.parts && this.parts()
    //   return parts && !parts.length
    // }
  },
  mounted() {
    this._blocElementName = 'chord'
  },
  destroyed() {
    this.clear()
  },
  methods: {
    clear() {
      this.parts = null
      this.report = null
      this.layout = null
      this.transpose = null
    },
    render({ fragments, updates, settings, report }) {
      //
      // Apply layout (this will update the grid style)
      //
      if (updates.has('events', 'renderer', 'layout')) {
        this.layout = settings.user.layout
        this.measureWidth = settings.chart.measureWidth
      }
      //
      // Check if it's empty
      //
      this.isEmpty = !report.hasMetric || !report.hasHarmony
      if (this.isEmpty) {
        this.clear()
        return
      }
      //
      // CHART BUILDER
      //
      if (updates.has('events', 'renderOptions', 'renderer', 'layout')) {
        const chars = settings.chars
        const renderOptions = settings.user.renderOptions
        const { parts, eventMap } = chartBuilder({
          fragments,
          chars,
          renderOptions,
          report
        })
        this.parts = () => parts
        this._settings = settings
        this._blocMap = eventMap
        this.report = report
        this.isEmpty = !parts.length
        console.log('CHART BUILDER', { parts, eventMap })
      }
      //
      // Transpose
      //
      this.transpose = settings.user.transpose
    },

    getSelectionElements(selection) {
      if (selection.isMeasure || selection.isLine) {
        const result = [this.getElement(selection.measureIndex, 'measure')]
        result.margin = 0
        return result
      }
    },

    getStyleSelection(layout) {
      const colsMatch = layout?.match(/(\d)-cols/)
      return !colsMatch
        ? 'margin-top: - var(--sheet-margin);  position: absolute;'
        : ''
    },

    getStyleGrid(row, layout) {
      const colsMatch = layout.match(/(\d)-cols/)
      if (colsMatch) {
        // GRID
        const colCount = colsMatch[1]
        const layout = Math.min(row.length, colCount)
        return {
          display: 'grid',
          gridTemplateColumns: `repeat(${layout}, 1fr)`
        }
      } else if (layout === 'wrap') {
        // WRAP
        return {
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fit, minmax(200px, 1fr))`
        }
      } else {
        return {
          display: 'flex'
        }
      }
    },

    getScrollTarget() {
      const blocMap = this._blocMap
      let headBloc = blocMap.get(this.head)[0]
      const measureIndex = headBloc.bloc.measure.index
      return this.getElement(measureIndex, 'measure')
    },

    selectFromRepeat(measure) {
      if (this.locked) return
      const chord = measure.cells[0][0]
      this.select(chord)
    },

    selectFromMeasure(measure) {
      if (this.locked) return
      const chord = measure.cells[0][0]
      this.select(chord)
    },

    selectFromChord(chord) {
      if (this.locked) return
      this.select(chord)
    },

    getEventIndex(measure) {
      return measure.cells[0][0].eventIndex
    }
  }
}
</script>

<style scoped>
.chart-renderer {
  text-align: center;
  --sheet-chord-background-color: #00000000;
}

.row {
  display: flex;
  align-items: center;
  margin: 0;
}
.grid {
  display: grid;
  flex: 1;
}

/* MEASURES */

.measure {
  min-width: var(--sheet-measure-width);
  min-height: var(--sheet-measure-height);
  border: solid 1px var(--sheet-color-line);
  margin-top: -1px;
  margin-left: -1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  flex: 1;
}

.measure.empty.anacrouse {
  display: none;
}

/* pattern X */

.pattern-x .blocs {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* pattern XX */

.pattern-xx .blocs {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  padding: 8px;
}

/* pattern XXXX */

.pattern-xxxx .blocs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  align-items: center;
  padding: 8px;
  grid-row-gap: 8px;
}

/* REPEAT */

.repeat {
  position: relative;
}

.repeat:before {
  position: absolute;
  left: -6px;
  top: -8px;
  width: calc(100% + 12px);
  height: calc(100% + 8px);
  content: ' ';
  border-radius: 2px;
}

.repeat svg {
  position: relative;
}
.repeat rect {
  fill: currentColor;
}

.repeat rect:first-child {
  transform: skewX(-45deg) translate(16px, 0px);
}

.repeat rect:last-child {
  transform: translate(14px, 12px);
}

/* BLOCS */

.blocs {
  position: relative;
  flex: 1;
}

.lines {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  stroke: var(--sheet-color-line);
  opacity: 0.5;
}

.bloc {
  padding: 0 8px;
  display: flex;
  justify-content: center;
}

/* CHORDS */

.chord:not(:last-child) {
  margin-right: var(--sheet-bloc-spacer);
}

/* SELECT RAW */

.chart-renderer:not(.locked) .chord,
.chart-renderer:not(.locked) .repeat {
  cursor: pointer;
}
/* .repeat:hover:before,
.chord:hover:before {
  background: var(--sheet-selection-color);
}
.chord:hover,
.repeat:hover {
  color: white !important;
} */

/* SELECTED EVENT */

.repeat.active,
.chord.active {
  text-decoration: underline;
}

.selected:before {
  background: var(--sheet-selection-color);
  opacity: 0.3;
}

.chord.selected,
.repeat.selected {
  color: inherit;
}
</style>
