<template>
  <div class="parts" v-if="parts">
    <div class="part" v-for="(part, partIndex) in parts()" :key="partIndex">
      <div class="part-name" v-if="part.showTitle">
        <span class="before"></span>
        <span class="content">{{ part.title }}</span>
        <span class="after"></span>
      </div>
      <slot v-bind:part="part"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Part',
  props: {
    parts: Function
  }
}
</script>

<style scoped>
.part-name {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 10px;
  align-items: center;
  padding-bottom: var(--sheet-part-gutter);
  padding-top: var(--sheet-part-gutter);
}
.part-name .before,
.part-name .after {
  height: 1px;
  background: var(--sheet-part-color);
  opacity: calc(var(--sheet-part-opacity) / 2);
}
.part-name .content {
  opacity: var(--sheet-part-opacity);
  color: var(--sheet-part-color);
  text-align: center;
  font-size: var(--sheet-part-font-size);
  text-transform: uppercase;
  user-select: none;
}
</style>
