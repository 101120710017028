<template>
  <div class="selection-bound">
    <div
      :class="selectionClass"
      :ref="`selection-${itemIndex}`"
      v-for="(item, itemIndex) in items"
      :key="itemIndex"
      :style="item.style"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SelectionBound',
  props: {
    items: Array
  },
  computed: {
    ...mapGetters('player', ['selection']),

    selectionClass() {
      return ['selection', this.selection.highlight]
    }
  },
  methods: {
    getProxy() {
      const p = this.$refs['selection-0']
      return p && p[0]
    }
  }
}
</script>

<style scoped>
.selection-bound > * {
  background: var(--sheet-selection-color);
  position: absolute;
  opacity: 0.3;
  border-radius: 2px;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-duration: 0.1s;
  transition-property: width, height, left, top;
  z-index: 1;
  mix-blend-mode: darken;
}
.selection.none {
  opacity: 0;
}
</style>
