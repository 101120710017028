import { until, timeout } from '../utils/tools-utils'

export default {
  props: {
    head: Number,
    selection: Object
  },
  data: () => ({
    selectionRows: []
  }),
  watch: {
    head(value) {
      if (this.$parent._rendering) return
      if (value >= 0) {
        this.updateHead(value, false)
      }
    },
    selection(value) {
      if (this.$parent._rendering) return
      if (value && !value.isEmpty) {
        this.updateSelection()
      } else {
        this.selectionRows = []
      }
    }
  },
  methods: {
    select(bloc, rawPos) {
      //
      // Called when a user click on a bloc element
      //
      const event = bloc.events.get(bloc.eventIndex)
      this.$emit('user-select', {
        event,
        rawPos: rawPos || bloc.rawPos,
        bloc
      })
    },

    updateHead(eventIndex) {
      const blocMap = this._blocMap
      const blocs = blocMap?.get(eventIndex) || []
      this.$emit('head', { eventIndex, blocs })
    },

    async updateSelection() {
      const selection = this.selection
      //
      // By default this will update Element for html renderer. Typically lyric
      // and chord renderer. Staff renderer will override this function.
      //
      if (!selection || selection.isEmpty || !this._blocMap) {
        this.selectionRows = []
        return
      }
      //
      // Get the blocs
      //
      const blocMap = this._blocMap
      const blocs = []
      //
      // Get selection elements
      //
      let elements
      if (this.getSelectionElements) {
        //
        // Hook to allows super to definite a specials elements
        //
        elements = this.getSelectionElements(selection)
      }
      if (!elements) {
        //
        // Get all elements from start to end
        //
        for (let i = selection.start; i <= selection.end; i++) {
          if (blocMap.has(i)) blocs.push(...blocMap.get(i))
        }
        elements = []
        blocs.forEach((bloc) => {
          const elt = this.getElement(bloc.eventIndex)
          if (elt) elements.push(elt)
        })
      }
      //
      // Emit selection event
      //
      if (blocMap.size) {
        const scrollTarget = this.getScrollTarget()
        this.$emit('selection', { scrollTarget })
        //
        // We need to wait for the end of the scroll
        //
        let scrollTargetTop
        await timeout(10)
        await until(() => {
          const scrollTargetBound = scrollTarget.getBoundingClientRect()
          const diff = scrollTargetTop - scrollTargetBound.top
          scrollTargetTop = scrollTargetBound.top
          return diff === 0
        })
      }
      //
      // Eval the position of selection rows
      //
      const rows = []
      const margin = 0 //isNaN(elements.margin) ? 4 : elements.margin
      const thisBound = this.$el.getBoundingClientRect()
      let currentRow
      let bound
      const openRow = () => {
        bound = {
          left: Number.MAX_VALUE,
          right: 0,
          top: Number.MAX_VALUE,
          bottom: 0
        }
        currentRow = { bound }
      }
      const closeRow = () => {
        bound.width = bound.right - bound.left
        bound.height = bound.bottom - bound.top + margin * 2
        bound.left -= thisBound.left
        bound.top -= thisBound.top + margin
        if (this.offsetSelection) {
          bound.left += this.offsetSelection.x
          bound.top += this.offsetSelection.y
        }
        delete bound.bottom
        delete bound.right
        currentRow.style = {}
        Object.keys(bound).map((key) => {
          return (currentRow.style[key] = `${
            Math.ceil(bound[key] * 100) / 100
          }px`)
        })
        rows.push(currentRow)
        currentRow = bound = null
      }

      elements.forEach((blocEl, blocElIndex) => {
        if (!blocEl) return
        const blocBound = blocEl.getBoundingClientRect()
        const rowChange = currentRow && blocBound.top > bound.bottom
        const isLast = blocElIndex === elements.length - 1
        if (rowChange) closeRow()
        if (!currentRow) openRow()
        bound.left = Math.min(bound.left, blocBound.left)
        bound.right = Math.max(bound.right, blocBound.right)
        bound.top = Math.min(bound.top, blocBound.top)
        bound.bottom = Math.max(bound.bottom, blocBound.bottom)
        if (isLast) closeRow()
      })

      //
      // We reverse rows. This is a trick to allow smooth transition. But that
      // should be ehanced.
      //
      rows.reverse()
      this.selectionRows = rows
    },

    getScrollTarget() {
      //
      // Overrided by chord renderer and not used by staff renderer. See
      // updateSelection(). This will set the scrollTarget used by Viewer.vue
      // to scroll the sheet during a selection.
      //
      const blocMap = this._blocMap
      let headBloc = blocMap.get(this.head)[0]
      return this.getElement(headBloc.eventIndex)
    },

    getElement(index, name) {
      //
      // Used only by lyric and chord renderer. See updateSelection()
      //
      name = name || this._blocElementName || 'bloc'
      const els = this.$refs[name + '-' + index]
      if (!els || !els.length) {
        console.warn(
          `The element ${name}-${index} has been requested, but it is not referenced`
        )
        return
      }
      return els[0].$el || els[0]
    }
  }
}
