import EventMap from '../models/data/EventMap'

class Part extends Array {}
class Row extends Array {}
class Measure extends Array {}
class Bloc extends Array {}
class Chord {}

const hiddenPartType = ['default']

export default ({ fragments, chars, renderOptions, report }) => {
  const parts = []
  let eventCount = 0
  const allChords = []
  let pendingPart, currentPart, currentRow, lastRaw

  report.showMetric = renderOptions.indexOf('metric') > -1
  report.showPart = renderOptions.indexOf('part') > -1

  if (!report.hasHarmony) {
    return { parts, eventMap: new EventMap() }
  }

  const createPart = (partType) => {
    const optionTitle = report.showPart
    const hiddenTitle = hiddenPartType.indexOf(partType) > -1
    currentPart = new Part()
    if (optionTitle && !hiddenTitle) {
      currentPart.showTitle = true
      currentPart.title = partType
    }
    currentPart.type = partType
    currentRow = null
    parts.push(currentPart)
  }
  //
  // Create Measures
  //
  // let lastMeasure
  fragments.forEach((fragment, fragmentIndex) => {
    if (pendingPart) {
      createPart(pendingPart)
      pendingPart = null
    }
    let blocs = []
    let chord
    let measureIsEmpty = true
    fragment.forEach((beat, beatIndex) => {
      const bloc = new Bloc()
      bloc.isEmpty = true
      bloc.class = ['bloc']
      beat.forEach((event) => {
        eventCount++
        event.forEach((context) => {
          const cname = context.name
          const isPart = cname === 'part' && context.raw != chars.empty
          const isHarmony = cname === 'chord'
          if (isPart && report.showPart) {
            if (beatIndex >= fragment.length / 2) {
              pendingPart = context.raw
            } else {
              createPart(context.raw)
            }
          }
          if (isHarmony) {
            let isEmpty = context.raw === chars.empty
            //
            // Create chord
            //
            chord = new Chord()
            chord.events = new Map() /*pendingEvents*/
            chord.eventIndex = event.index
            if (lastRaw != context.raw && !isEmpty) {
              chord.raw = lastRaw = context.raw
            } else {
              chord.raw = chars.empty
              isEmpty = true
            }
            chord.rawPos = Object.assign({}, context.rawPos)
            chord.class = ['chord']
            chord.start = event.position
            chord.isEmpty = isEmpty
            chord.emptyChar = report.showMetric ? '•' : '-'
            if (!isEmpty) bloc.isEmpty = false
            chord.events.set(event.index, event)
            bloc.push(chord)
            allChords.push(chord)
          }
        })
      })
      if (!bloc.isEmpty) measureIsEmpty = false
      blocs.push(bloc)
    })

    //
    // Allow to evaluate the pattern of the bloc.
    //
    const isPattern = (count) => {
      if (blocs.length % count === 0) {
        let groups = []
        const step = blocs.length / count
        const valid = blocs.every((bloc, blocIndex) => {
          const groupIndex = Math.floor(blocIndex / step)
          const isOnGroupIndex = groupIndex === blocIndex / step
          let group = groups[groupIndex]
          if (!group) {
            groups[groupIndex] = []
          }
          groups[groupIndex].push(bloc)
          return bloc.isEmpty || isOnGroupIndex
        })
        if (valid) {
          //
          // Merge each blocs of the groups
          //
          groups = groups.map((group) => {
            const firstBloc = group[0]
            for (let i = 1; i < group.length; i++) {
              const mergedBloc = group[i]
              mergedBloc.forEach((chord) => {
                firstBloc.push(chord)
              })
            }
            return firstBloc
          })
          //
          // Reorder blocs and add spacer for display
          //
          if (count === 4) {
            measure.cells.push({}, groups[1], {})
            measure.cells.push(groups[0], {}, groups[3])
            measure.cells.push({}, groups[2], {})
          } else if (count === 2) {
            measure.cells.push(groups[0], {}, {}, groups[1])
          } else {
            measure.cells.push(groups[0])
          }
          measure.blocs.push(...groups)
          measure.pattern = ''.padStart(count, 'x')
          return true
        }
      }
    }
    //
    // Create the measure
    //

    const measure = new Measure()
    measure.blocs = []
    measure.cells = []
    measure.index = fragmentIndex
    measure.class = ['measure']
    if (measureIsEmpty) measure.class.push('empty')
    measure.isEmpty = measureIsEmpty
    if (fragmentIndex === 0) {
      const nextFragment = fragments[fragmentIndex + 1]
      if (nextFragment && nextFragment.length != fragment.length) {
        measure.isAnacrouse = true
        measure.class.push('anacrouse')
      }
    }
    if (fragmentIndex % 2 != 0) measure.class.push('odd')
    let isEmpty = !blocs.some((bloc) => !bloc.isEmpty)
    if (isEmpty && fragmentIndex > 0 && !report.showMetric) {
      measure.pattern = '%'
      measure.repeat = true
      let emptyBloc
      blocs.forEach((bloc, blocIndex) => {
        if (blocIndex === 0) {
          emptyBloc = bloc
        } else {
          emptyBloc.push(...bloc)
        }
      })
      measure.cells.push(emptyBloc)
      measure.blocs.push(emptyBloc)
    } else {
      //
      // Check the pattern, if none if found, the default x pattern is applied
      //
      if (isEmpty || (!isPattern(1) && !isPattern(2) && !isPattern(4))) {
        measure.pattern = 'x'
        measure.cells.push(...blocs)
        measure.blocs.push(...blocs)
      }
      measure[measure.pattern] = true
      measure.class.push(`pattern-${measure.pattern}`)
    }

    //
    // Create default part (if none exists)
    //
    if (!currentPart) {
      createPart('default')
    }

    if (!currentRow) {
      currentRow = new Row()
      currentPart.push(currentRow)
    }
    currentRow.push(measure)
    // lastMeasure = measure
  })
  //
  // Merging chords && create bloc map
  //
  const eventMap = new EventMap()
  let eventIndex = 0
  let lastChord
  lastRaw = ''
  parts.forEach((part) => {
    part.forEach((row) => {
      row.forEach((measure) => {
        measure.isEmpty = true
        // const filteredMeasure = measure.filter('m'=>m)
        measure.blocs.forEach((bloc) => {
          if (!(bloc instanceof Bloc)) return
          //
          // Merging chords
          //
          if (!report.showMetric) {
            const pendingMerged = []
            bloc.forEach((chord, index) => {
              const target = bloc[index - 1]
              if (chord.isEmpty && target) {
                pendingMerged.unshift({ index, chord, target })
              }
            })
            pendingMerged.forEach((pending) => {
              pending.target.events = new Map([
                ...pending.chord.events,
                ...pending.target.events
              ])
              const targetPos = pending.target.rawPos
              const chorPos = pending.chord.rawPos
              const rawPosEnd = chorPos.pos + chorPos.length
              targetPos.length = rawPosEnd - targetPos.pos
              bloc.splice(pending.index)
            })
          }
          //
          // Update map
          //
          bloc.forEach((chord) => {
            const sortedEvents = Array.from(chord.events)
              .sort((a, b) => a[1].index - b[1].index)
              .map((a) => a[1])
            sortedEvents.forEach((event) => {
              //
              // Some event can be not linked to an chord. That is the case for
              // the second event in the example below
              // | A     - |
              // | [a b] - |
              // These events will be linked to the last event. That occurs only
              // for sub beat event, so the last event is not undefined.
              //
              eventMap.fill(eventIndex, event.index, lastChord)
              eventMap.set(event.index, chord)
              eventIndex = event.index
              lastChord = chord
            })
            //
            // Ultime chord setting
            //
            chord.bloc = bloc
            if (chord.raw === chars.empty) {
              if (report.showMetric) {
                chord.raw = '•'
              } else if (lastRaw) {
                chord.raw = lastRaw
              }
            }
            if (!chord.empty) {
              lastRaw = chord.raw
            }
          })
          bloc.measure = measure
          if (!bloc.isEmpty) measure.isEmpty = false
        })
        // lastMeasure = measure
        measure.row = row
      })
    })
  })

  if (eventCount != eventMap.size) {
    throw new Error('WTF : events count and eventMap must be equal')
  }

  return { parts, eventMap }
}
