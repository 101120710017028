<template>
  <div v-on="$listeners">
    <span>{{ getChord(raw, emptyChar) }}</span>
  </div>
</template>

<script>
import { chordParserFactory, chordRendererFactory } from 'chord-symbol'

const parseChord = chordParserFactory()
const chordMap = new Map()

export default {
  name: 'Chord',
  props: {
    raw: String,
    transpose: Number,
    emptyChar: {
      type: String,
      default: '-'
    }
  },
  computed: {
    renderChord() {
      return chordRendererFactory({
        transposeValue: this.transpose,
        useShortNamings: true
      })
    }
  },
  methods: {
    getChord(value, emptyChar) {
      if (!emptyChar) emptyChar = '-'
      if (value === emptyChar) return value
      let chord = chordMap.get(value)
      if (!chord) {
        chord = parseChord(value)
        chordMap.set(value, chord)
      }
      return this.renderChord(chord)
    }
  }
}
</script>

<style scoped>
.chord {
  font-size: var(--sheet-chord-font-size);
  font-weight: var(--sheet-chord-weight);
  font-family: var(--sheet-chord-font-family);
  display: inline-block;
  /* align-self: center; */
  /* margin-bottom: 2px; */
  position: relative;
  user-select: none;
  padding: 0 2px;
}
.chord:not(.empty) > * {
  position: relative;
  z-index: 1;
  margin: 2px;
}

.chord:before {
  position: absolute;
  left: 2px;
  right: 2px;
  top: 0;
  height: 100%;
  content: ' ';
  background: var(--sheet-chord-background-color);
  border-radius: 2px;
}

.chord.empty {
  opacity: 0;
  width: 0;
  padding: 0;
}
</style>
